import {Component, Input} from '@angular/core';
import {MatDialog} from '@angular/material';
import {FormDialogComponent} from './form-dialog/form-dialog.component';

@Component({
  selector: 'main-form',
  templateUrl: 'main-form.html',
  styleUrls: ['main-form.css'],
})

export class mainform {
  @Input() formObjectData: any;
  public key: string;
  constructor(public dialog: MatDialog) {

  }
  ngOnInit() {
  }
  ngOnChange() {
  }
  onEdit() {
    const dialogRef = this.dialog.open(FormDialogComponent, {
      height: '400px',
      width: '600px',
      disableClose: true,
      hasBackdrop: true,
      data: {
        id : this.formObjectData.id,
        key: this.formObjectData.key,
        value : this.formObjectData.value,
        description : this.formObjectData.description,
        isSet : this.formObjectData.isSet,
        isShared : this.formObjectData.isShared }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });

  }
}
