import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface TableDialogData {
  description: string;
}

@Component({
  selector: 'app-table-dialog',
  templateUrl: './table-dialog.component.html',
  styleUrls: ['./table-dialog.component.css']
})

export class TableDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<TableDialogData>, @Inject(MAT_DIALOG_DATA) public data: TableDialogData) {
   }

  ngOnInit() {
  }
  onCancel(): void {
    this.dialogRef.close();
  }
}
