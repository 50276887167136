import { Injectable } from '@angular/core';
import { HttpClient, JsonpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable, Subscriber } from 'rxjs';
import { environment } from '../../environments/environment';
import { ConfigModel } from '../_models/config';
import { map } from 'rxjs/operators';
import { ConfigProvider } from 'src/app/shared/config.provider';

@Injectable()
export class ConfigurationService {

  constructor(private http: HttpClient, private config: ConfigProvider) { }

  public getConfigurations(): Observable<ConfigModel[]> {
    const url = `${this.config.getConfig().ApiBaseUrl}/Config`;
    return this.http.get(url).pipe(map(c => this.mapConfigResponse(c)));
  }

  public updateConfiguration(data: any): Promise<any> {
    return  this.http.put(`${this.config.getConfig().ApiBaseUrl}/config/update/1`, data).toPromise();
  }


  public checkUrl(verifyUrlModel: any): Observable<any[]> {
    const url = `${this.config.getConfig().ApiBaseUrl}/config/checkUrl`;
    return this.http.post<any>(url, verifyUrlModel);
  }

  public getTenants(): Promise<any> {
    const url = `${this.config.getConfig().ApiBaseUrl}/config/getTenants`;
    return this.http.get<any>(url).toPromise();
  }


  public updateManyConfigurations(data: any): Promise<any> {
    return  this.http.put(`${this.config.getConfig().ApiBaseUrl}/config/updatemany`, data).toPromise();
  }

  public getTreeNodes(data: string): Observable<any[]> {
    const url = `${this.config.getConfig().ApiBaseUrl}/Config/${data}`;
    return this.http.get<any>(url);
  }
  public getValues(data: any): Observable<ConfigModel[]> {
    const url = `${this.config.getConfig().ApiBaseUrl}/Config/getByKey/${data}`;
    return this.http.get<any>(url);
  }

  private mapConfigResponse(data: any): ConfigModel[] {
    const configs: ConfigModel[] = [];
    for (const datarow of data.data) {
      const config: ConfigModel = this.mapSingleConfig(datarow);
      configs.push(config);
    }
    return configs;

  }
  private mapSingleConfig(data: any): ConfigModel {
    const config: ConfigModel = {
      id: data.id || '',
      key: data.key,
      value: data.value,
      modifiedOn: data.modifiedOn || '',
      isSet: data.isSet,
      isShared: data.isShared,
      description: data.description,
      isDirty : false
    };
    return config;
  }
}
