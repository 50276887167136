import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
export interface ConfigurationModel {
    ApiBaseUrl;
}
@Injectable()

export class ConfigProvider {

    private config: ConfigurationModel = null;

  constructor(private http: HttpClient) {
    }

    public getConfig(): ConfigurationModel {
        return this.config;
    }

    load() {
        return new Promise((resolve, reject) => {
            this.http
                .get<any>('./assets/config/config.json')
                .subscribe(response => {
                    this.config = response;
                    resolve(true);
                },
                    error => {
                        reject('error occured');
                        console.error('Error occured while fetching config file.');
                    });
        });
    }



  public init(): void {
  }


}
