import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { FormGroup, FormArray, FormControl } from '@angular/forms';

@Component({
  selector: 'app-configuration-form',
  templateUrl: './configuration-form.component.html',
  styleUrls: ['./configuration-form.component.css']
})
export class ConfigurationFormComponent implements OnChanges {
  @Input() public disabled = false;
  @Input() public responseData;
  @Input() public showSaveButton = true;
  @Input() public forms: FormArray = new FormArray([]);
  @Input() public title = 'Title';
  @Output() public add: EventEmitter<any> = new EventEmitter<any>();
  @Output() public cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() public remove: EventEmitter<number> = new EventEmitter<number>();
  @Output() public save: EventEmitter<number> = new EventEmitter<number>();
  public configurationForm: FormGroup;
  public errors = {};
  constructor() { }

  ngOnInit() {

  }

  public ngOnChanges(): void {
    this.forms.controls.forEach(element => {
      if (element.value.disabledRow) {
        element.disable();
      }
    });
  }
}
