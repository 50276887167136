import { Component, OnInit, Input } from '@angular/core';
import { ConfigurationService } from '../_services/config.service';
import { User } from '../_models';
import { first } from 'rxjs/operators';
import { ITreeOptions, TreeNode } from 'angular-tree-component';


@Component({
  selector: 'ngbd-modal-config',
  templateUrl: 'home.component.html',
  styleUrls: ['home.component.css'],
})
export class HomeComponent implements OnInit {
  @Input() responseData: any;
  @Input() breadCrumbsSend: any;
  breadCrumbs: any;
  tenantsList: any;
  currentUser: User;
  listOfConfig: any[];
  currentValue: any;
  loading: boolean;
  public formObjectData: any[];
  public nodes: Array<object> = [];
  public options: ITreeOptions = {
    isExpandedField: 'expanded',
    idField: 'uuid',
    getChildren: this.getChildren.bind(this),
    nodeHeight: 23,
    allowDrag: (node) => {
      return false;
    },
    allowDrop: (node) => {
      return false;
    },
  };

  constructor(private configService: ConfigurationService
  ) {

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

  }
  ngOnInit() {
    this.loading = true;
    this.getListOfTenants();

  }
  deleteUser(id: number) {
  }
  mapApiCallData(treeData): any {
    const treeDataArray = [];
    let id = 0;
    treeData.data.map((item) => {
      const treeDataObject = {};
      const parts = item.toString().split('.');
      console.log(parts);
      treeDataObject['id'] = id;
      treeDataObject['name'] = parts[parts.length - 1];
      treeDataObject['path'] = item.toString();
      treeDataObject['hasChildren'] = true;
      treeDataObject['externelId'] = id + 1;
      treeDataArray.push(treeDataObject);
      id++;

    });
    return treeDataArray;
  }
  mapValues(data): any {
    this.currentValue = data.data;
    this.formObjectData = data.data;
    this.responseData = data.data;
  }
  private loadConfigurations() {
    this.configService.getConfigurations()
      .pipe(first())
      .subscribe(
        (result) => {
          this.listOfConfig = result;

        });
  }
  onEvent(event) {
    this.breadCrumbs = event.node.data.path;
    this.breadCrumbsSend = event.node.data.path;
    this.configService.getValues(event.node.data.path).pipe(first())
      .subscribe(
        (result) => {
          if (result !== undefined) {
            this.mapValues(result);
            this.loading = false;
          }
        });
  }

  getTreeNodes() {
    this.configService.getTreeNodes('null').pipe(first())
    .subscribe(
      (result) => {
        this.nodes = (this.mapApiCallData(result));
        this.loading = false;
        return this.nodes;
      });
  }

  getChildren(node: TreeNode) {
    return new Promise((resolve, reject) => {
      this.configService.getTreeNodes(node.data.path).subscribe((result) => {
        const data = result;
        return resolve(this.mapApiCallData(data));
      });
    });
  }

  getListOfTenants() {
    this.loading = true;
    localStorage.removeItem('tenantId');
    this.configService.getTenants().then((result) => {
      this.tenantsList = result.data;
      this.loading = false;
      // this.getTreeNodes();
    }).catch(() => {
      this.loading = false;
    });
  }

  setTenantValue(tenantId: any) {
    this.getTreeNodes();
    this.responseData = undefined;
    localStorage.setItem('tenantId', tenantId);

  }

}

