import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfigurationService  } from '../../_services/config.service';
export interface FormDialogData {
  id: number;
  value: string;
  key: string;
  description: string;
  isSet: boolean;
  isShared: boolean;

}

@Component({
  selector: 'app-form-dialog',
  templateUrl: './form-dialog.component.html',
  styleUrls: ['./form-dialog.component.css']
})
export class FormDialogComponent implements OnInit {
  constructor(private configService: ConfigurationService,
    public dialogRef: MatDialogRef<FormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FormDialogData) {
      this.dialogRef.disableClose = true;
    }

  onCancel(): void {
    this.dialogRef.close();
  }
//  onSave(): any{
//    console.log(this.data, 'ts')
//   // this.configService.updateConfiguration(this.data)
//   .pipe()
//   .subscribe(
//    (result) => {
//        console.log(result);
// });
//  }
  ngOnInit() {
  }

}
