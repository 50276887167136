﻿import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ConfigProvider } from 'src/app/shared/config.provider';

@Injectable()
export class AuthenticationService {
    constructor(private http: HttpClient, private config: ConfigProvider) { }

    login(username: string, password: string) {
        return this.http.get(`${this.config.getConfig().ApiBaseUrl}/login?Username=${username}&Password=${password}`)
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                }
                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
    }
}
