import { Component, OnInit, Input , Output , ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ConfigurationService } from '../_services/config.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { TableDialogComponent } from './table-dialog/table-dialog.component';
import { first } from 'rxjs/operators';
import { getLocaleDayNames } from '@angular/common';
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
  test: boolean;
}
export interface ConfigModel {
  id: number;
  key: string;
  value: string;
  modifiedOn: Date;
  isSet: Boolean;
  isShared: Boolean;
  description: string;
}
const ELEMENT_DATA: PeriodicElement[] = [
  { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H', test: true },
  { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He', test: false },
  { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li', test: true },
  { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be', test: true },
  { position: 5, name: 'Boron', weight: 10.811, symbol: 'B', test: false },
  { position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C', test: true },
  { position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N', test: true },
  { position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O', test: false },
  { position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F', test: true },
  { position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne', test: true },
];


@Component({
  selector: 'table-component',
  styleUrls: ['table-component.css'],
  templateUrl: 'table-component.html',
})
export class TableComponent implements OnInit {
  @Input() responseData;
  @Input() breadCrumbsSend;
  @Output() Input;
  public activeForm;
  public loading: boolean;
  @ViewChild('myElement') myElement: ElementRef;
  testData: any[];
  displayedColumns: string[] = ['id', 'key', 'value', 'description', 'savebtn'];
  dataSource = ELEMENT_DATA;
  public tableForm: FormGroup;
  constructor(public dialog: MatDialog, private configService: ConfigurationService, private cd: ChangeDetectorRef,
    private toastr: ToastrService,
    private fb: FormBuilder
  ) {
    this.activeForm = [];
    this.loading = false;
  }
  ngOnInit() {
    this.generateForm();
  }
  private generateForm(): void {
    this.testData = this.responseData;
    this.tableForm = this.fb.group({
      value: [this.responseData ? this.responseData : '']
    });
    const i = this.responseData.length - 1;
    const f = 0;
    // its look like no used of below line of code.
    // for (const attribute of this.responseData) {
    //   this.tableForm[i] = this.fb.group(attribute);
    //   i++;
    // }

  }

  onEdit(data) {
      const dataToSend = {
        key : this.breadCrumbsSend + '.' + data.key,
        value : data.value };
      this.loading = true;
      this.configService.updateConfiguration(dataToSend).then(() => {
      this.loading = false;
      data.isSet = true;
      this.loadData();
      this.toastr.success('Successfully Updated');
    }).catch(() => {
      this.loading = false;
      this.toastr.error('Error');
    });
  }

  setCheckboxValue($event, element) {
    element.isDirty = true;
    element.value = $event.target.checked;
    this.cd.detectChanges();
  }

  setDropDownValue(value, element) {
    element.value = value;
    element.isDirty = true;
  }
  onChangeValues(element) {
    element.isDirty = true;

  }

  saveManyConfigurations() {
    this.responseData.map((x) => {
       if (x.type == 'url' && x.value && x.value.length > 0) {
         if (!this.validateURL(x.value)) {
          this.toastr.error(x.key + ' is not valid.');
          return;
         }
       }
    });
    const configurationData = this.responseData.map((x) => { if (x.isDirty) { return {key: this.breadCrumbsSend + '.' + x.key, value: x.value }; }});
    const data = configurationData.filter(Boolean);
    this.loading = true;
    this.configService.updateManyConfigurations(data).then(() => {
      this.loading = false;
      data.isSet = true;
      this.loadData();
      this.toastr.success('Successfully Updated');
    }).catch(() => {
      this.loading = false;
      this.toastr.error('Error');
    });
  }

  loadData() {
    return this.configService.getValues(this.breadCrumbsSend).pipe(first())
      .subscribe(
        (result) => {
          const res: any = result;
          if (result !== undefined) {
            this.responseData = res.data;
          }
        });
  }

  verifyUrl(url: string) {
    if (!url || url.length <= 0) {
      this.toastr.error('Url is empty.');
      return;
    }
    const verifyUrlModel = {
      VerifyUrl : url
    };
    this.loading = true;
    return this.configService.checkUrl(verifyUrlModel).pipe(first())
    .subscribe(
      (result) => {
        this.loading = false;
        const res: any = result;
        if (res.data !== undefined) {
          // this.responseData = res.data.$values;
          this.toastr.success('Url is valid.');
        } else {
          // this.responseData = res.data.$values;
          this.toastr.error('Url is not valid.');
        }
      });

  }

  validateURL(str) {
    const regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    if (!regex .test(str)) {
      return false;
    } else {
      return true;
    }
  }





  public openModal(data: any) {
    const dialogRef = this.dialog.open(TableDialogComponent, {
      height: '200px',
      width: '400px',
      disableClose: false,
      data: { description: data },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`closed`);
    });

  }
  public onKeyUp($event) {
    if ($event != null) {
      if (this.activeForm.indexOf($event.target.id) === -1) {
        this.activeForm.push(parseInt($event.target.id));
      }
    }

  }
  public validator(event) {
    return false;
    // return ! (this.activeForm.indexOf(event.id) !== -1);
  }
}
