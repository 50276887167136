﻿import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { fakeBackendProvider } from './_helpers';
import { AppComponent } from './app.component';
import { routing } from './app.routing';
import { AlertComponent } from './_directives';
import { AuthGuard } from './_guards';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { AlertService, AuthenticationService, } from './_services';
import { ConfigurationService } from './_services/config.service';
import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { RegisterComponent } from './register';
import { TreeModule } from 'angular-tree-component';
import { SidenavDrawer } from './sidebar/sidenav-drawer';
import { mainform } from './form/main-form';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { TableComponent } from '../app/table/table-component';
import { ConfigurationFormComponent } from '../app/configuration-form/configuration-form.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfigProvider } from './shared/config.provider';

import {
    MatInputModule, MatListModule, MatPaginatorModule, MatProgressSpinnerModule, MatSidenavModule, MatSortModule,
    MatTableModule,
    MatToolbarModule,
    MatCheckboxModule,
    MatSelectModule,
    MatFormFieldModule,
    MatDividerModule,
    MatButtonModule,
    MatMenuModule

} from '@angular/material';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormDialogComponent } from './form/form-dialog/form-dialog.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { TableDialogComponent } from './table/table-dialog/table-dialog.component';
export function configProviderFactory(provider: ConfigProvider) {
    return () => provider.load();
  }
@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(
            {
            positionClass: 'toast-top-center',
            preventDuplicates: true
          }
          ),
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        routing,
        MatInputModule,
        MatListModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatSidenavModule,
        MatSortModule,
        MatTableModule,
        MatToolbarModule,
        MatCheckboxModule,
        MatDialogModule,
        MatSelectModule,
        TreeModule.forRoot(),
        MatFormFieldModule,
        MatDividerModule,
        MatButtonModule,
        MatMenuModule,
        NgbModule,
        MatIconModule,


    ],
    exports: [
        MatInputModule,
        MatListModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatSidenavModule,
        MatSortModule,
        MatTableModule,
        MatToolbarModule,
        MatCheckboxModule,
        MatSelectModule,
        MatFormFieldModule,
        MatIconModule,
        MatTooltipModule

    ],
    declarations: [
        AppComponent,
        AlertComponent,
        HomeComponent,
        LoginComponent,
        RegisterComponent,
        SidenavDrawer,
        mainform,
        FormDialogComponent,
        TableComponent,
        TableDialogComponent,
        ConfigurationFormComponent
    ],
    entryComponents: [
        FormDialogComponent,
        TableDialogComponent
    ],
    providers: [
        ConfigProvider,
        { provide: APP_INITIALIZER, useFactory: configProviderFactory, deps: [ConfigProvider], multi: true },
        AuthGuard,
        AlertService,
        AuthenticationService,
        ConfigurationService,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

        // provider used to create fake backend
        fakeBackendProvider
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
