import {Component, OnInit} from '@angular/core';

/** @title Basic drawer */
@Component({
  selector: 'sidenav-drawer',
  templateUrl: 'sidenav-drawer.html',
  styleUrls: ['sidenav-drawer.css'],
})
export class SidenavDrawer {
    OnInit() {
    }
}
