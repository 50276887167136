import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        if (request.url.indexOf('./assets/config/config.json') > -1) {
            return next.handle(request);
        }
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        let tenantId = '';
        if (localStorage.getItem('tenantId')) {
        tenantId = localStorage.getItem('tenantId');
        }
        request = request.clone({
            setHeaders: {
                'TenantId': tenantId
            }
        });

        return next.handle(request);
    }
}
